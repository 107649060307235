<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>退换货管理</el-breadcrumb-item>
        <el-breadcrumb-item>退换货原因</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">退换货原因：</div>
                <el-input v-model="search.reason" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="orderRefundCancelAdd" @click="goAdd">新增原因</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" label="退换货原因" min-width="200">
              <template slot-scope="scope">
                <span class="contennt-desc">{{ scope.row.reason }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="备注信息" min-width="300">
              <template slot-scope="scope">
                <span class="contennt-desc">{{ scope.row.remarks }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="sort" label="排序">
            </el-table-column>
            <el-table-column align="center" label="当前状态">
              <template slot-scope="scope">
                <el-switch
                    @change="changeStatus(scope.row,$event)"
                    :disabled="orderRefundCancelUpd?false:true"
                    v-model="scope.row.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="orderRefundCancelUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="orderRefundCancelDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm">
        <el-form-item label="退换货原因：" prop="reason">
          <span v-if="type === 'details'">{{ upData.reason }}</span>
          <el-input v-else v-model="upData.reason" placeholder="请输入退换货原因"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <span v-if="type === 'details'">{{ upData.sort }}</span>
          <el-input-number v-else type="number" :step-strictly="true" :controls="false" :step="1"
                           v-model="upData.sort" placeholder="请输入排序"></el-input-number>
        </el-form-item>
        <el-form-item label="状态：" prop="status" v-if="type === 'details'">
          <span v-if="upData.status === false">未使用</span>
          <span v-if="upData.status === true">在使用</span>
        </el-form-item>
        <el-form-item label="退换货备注：" style="width: 100%;">
          <span v-if="type === 'details'">{{ upData.remarks }}</span>
          <el-input
              v-else
              type="textarea"
              placeholder="请输入退换货备注"
              v-model="upData.remarks"
              :autosize="{ minRows: 6, maxRows: 8}"
              maxlength="300"
              show-word-limit
          />
        </el-form-item>
      </el-form>
      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validatorSort = (rule, value, callback) => {
      if (value === 0) {
        callback(new Error("请输入排序"));
      } else {
        callback();
      }
    }
    return {
      search: {
        reason: "",
        type: 2,
      },
      type: "",
      tableList: [],
      dialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        reason: "",
        remarks: "",
        sort: 0,
        status: 0,
      },
      rules: {
        reason: [{required: true, message: "请输入退换货原因", trigger: "blur"}],
        sort: [{required: true, validator: validatorSort, trigger: "blur"}],
        status: [{required: true, message: "请选择使用状态", trigger: "blur"}],
      },
      orderRefundCancelAdd: false,
      orderRefundCancelDel: false,
      orderRefundCancelUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;

      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getCancel(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              const list = res.data.data.records;
              list.forEach(item => {
                item.status = item.status === 0 ? false : true;
              })
              _this.tableList = list;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "orderRefundCancel:add") {
          _this.orderRefundCancelAdd = true;
        }
        if (v == "orderRefundCancel:del") {
          _this.orderRefundCancelDel = true;
        }
        if (v == "orderRefundCancel:upd") {
          _this.orderRefundCancelUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (!_this.upData.id) {
            _this.$api.addCancel(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("添加成功");
                _this.btnLoading = false;
                _this.handleClose();
                _this.getData();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          } else {
            _this.$api.editCancel(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("修改成功");
                _this.btnLoading = false;
                _this.getData();
                _this.handleClose();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        reason: "",
        remarks: "",
        sort: 0,
        status: 0,
        type: 2
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        reason: "",
        type: 2,
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'add';
      _this.upData = {
        reason: "",
        remarks: "",
        sort: 0,
        status: 0,
        type: 2
      }
      _this.upList = [];
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    changeStatus(item,e) {
      let _this = this;
      const resqusetData = JSON.parse(JSON.stringify(item));
      resqusetData.status = e?1:0;
      _this.$api.editCancel(resqusetData).then(res => {
        if (res.data.code == 200) {

        }
      }).catch(() => {

      });
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delCancel({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-textarea {
  //::-webkit-scrollbar{
  //  width:0;
  //  height:0;
  //}
  /deep/ .el-input__count {
    //right: 50px;
  }
}

.el-input-number {
  width: 100%;

  /deep/ .el-input__inner {
    text-align: left;
  }
}

.goods-box {
  margin-bottom: 20px;

  .search-title {
    width: 100px;
  }
}
</style>
